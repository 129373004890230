@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

.referral-section {
    margin-bottom: 40px;

    .referral-title {
        margin-bottom: 10px;
    }
}

.referral-banner {
    @include mixins.border-radius(12px);
    background-color: #F0E9DE;
    overflow: hidden;

    &:first-child {
        @media (max-width: breakpoints.$screen-sm-max) {
            margin-top: 20px;
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    .content {
        padding: 20px;
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        position: relative;

        .premium-icon {
            @include mixins.square(66px);
            background-color: #F0E9DE;
            @include mixins.border-radius(50%);
            position: absolute;
            top: -33px;
            margin: auto;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                @include mixins.square(36px);
            }

            + div {
                z-index: 1;
                position: relative;
            }
        }
    }
}

.your-referral-code {
    position: relative;

    .section-title {
        margin-bottom: 8px;
    }

    .copied-bubble {
        position: absolute;
        right: 0;
        margin-top: -40px;

        > div:first-child {
            background-color: #000;
            color: #FFF;
            padding: 5px 10px;
            font-size: 14px;
            @include mixins.border-radius(15px);
        }

        .triangle {
            clip-path: polygon(0 50%, 50% 100%, 100% 50%);
            background-color: #000;
            width: 14px;
            height: 14px;
            position: absolute;
            top: 22px;
            right: 15px;
        }
    }

    .input-section {
        display: flex;

        > input {
            white-space: nowrap;
            overflow-x: auto;
            background-color: #FFF;
            border: 1px solid #CCC;
            padding: 0 5px;
            @include mixins.border-radius(4px 0 0 4px);
            border-right: none;
            width: calc(100% - 44px);
            font-size: 14px;
            text-align: center;
        }

        > button {
            border: none;
            width: 44px;
            height: 44px;
            background-color: #999999;
            color: #FFF;
            @include mixins.border-radius(0 4px 4px 0);
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: 18px;
            }
        }
    }

    > p {
        margin-top: 4px;
        font-size: 14px;
    }
}

.referral-counter-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    @media (min-width: breakpoints.$screen-lg-min) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

    .section-title {
        text-align: center;
    }

    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        @media (min-width: breakpoints.$screen-sm-min) {
            justify-content: center;
        }

        li {
            @include mixins.border-radius(12px);
            @include mixins.aspect-ratio(10px, 10px);
            background: #CCC;
            width: calc(20% - 4px);
            color: #FFF;
            font-weight: 700;
            font-size: 28px;
            margin-right: 5px;

            .content {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            @media (min-width: breakpoints.$screen-sm-min) {
                @include mixins.square(65px);
                margin-left: 5px;
            }

            &.reached {
                background: rgb(224, 12, 0);
                background: linear-gradient(to bottom, rgba(224, 12, 0, 1) 0%,rgba(192, 14, 0, 1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e00c00', endColorstr='#c00e00', GradientType=0 );
            }
        }
    }
}

.referral-list {
    margin-top: 20px;

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
        border-bottom: 1px solid #DDD;

        > div {
            &:first-child {
                width: calc(100% - 100px);
            }

            &:last-child {
                font-weight: 700;
                font-size: 12px;

                &.upgrade {
                    color: #00B91C;
                }
            }
        }
    }
}

.referral-nagbar {
    border-radius: 12px;
    color: #fff;
    padding: 20px;
    text-align: left;
    margin-bottom: 20px;
    box-shadow: 0 0 6px 0 rgba(0,0,0,.05);
    position: relative;

    .ad-referral-content {
        position: relative;
        z-index: 1;

        h3, .aux-text {
            @include mixins.size-h3();
        }

        .referral-description {
            margin: 7px 0 21px 0;
        }

        .mp-main-button.bt-md {
            text-align: center;
        }

        .text-normal {
            font-weight: 400;
        }
    }

    .ad-referral-background {
        width: 100%;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 12px;
        background: #FFF;
        overflow: hidden;

        .ad-referral-gradient {
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.2) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc000000', endColorstr='#33000000',GradientType=1);
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
}
