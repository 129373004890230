@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;
@use 'placeholders' as *;

button[disabled], html input[disabled] {
    cursor: not-allowed;
}

.mp-main-button, a.mp-main-button {
    font-weight: 700;
    @include mixins.border-radius(4px);
    background-color: var(--mp-red);
    color: #FFF;
    cursor: pointer;
    text-decoration: none;
    border: 2px solid var(--mp-red);
    display: inline-block;
    outline: none;

    .bt-content {
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            margin-right: 5px;
        }
    }

    &:not(:disabled):hover {
        background-color: var(--mp-red-hover);
        border-color: var(--mp-red-hover);
        color: #FFF;
    }

    &:active {
        background-color: var(--mp-red-active);
        border-color: var(--mp-red-active);
        color: #FFF;
    }

    &:visited {
        color: #FFF;
    }

    &.bt-sm {
        font-size: 16px;
        padding: 4px 15px;
    }

    &.bt-md {
        font-size: 18px;
        padding: 9px 15px;
    }

    &.bt-lg {
        font-size: 20px;
        padding: 12px 15px;
    }

    &.bt-shadow {
        box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2);
    }

    &.outline-white {
        background-color: transparent;
        border: 2px solid #FFF;
        width: 140px;
    }

    &.gray-borderless {
        background-color: #DDD;
        color: #222;
        border: none;

        &:disabled {
            opacity: 1;
        }
    }

    &.white-borderless-normal {
        background-color: transparent;
        width: 140px;
        font-weight: 400;
        border: none;
    }

    &.outline-red {
        background-color: transparent;
        border: 2px solid var(--mp-red);
        color: var(--mp-red);
    }

    &.outline-purple {
        background: transparent;
        border: 2px solid var(--boost-purple);
        color: var(--boost-purple);
    }

    &.white-bg {
        background-color: #FFF;
        border: 2px solid #FFF;
        color: var(--mp-main-dark);
    }

    &.white-bg-red-text {
        background-color: #FFF;
        color: var(--mp-red);
    }

    &.boost-purple-regular {
        background-color: var(--boost-purple);
        border: 2px solid var(--boost-purple);
        color: var(--white);

        &:hover {
            background-color: var(--boost-purple);
            border: 2px solid var(--boost-purple);
        }
    }

    &.full-width {
        width: 100%;
    }

    &:disabled {
        opacity: .3;
    }

    @media (max-width: breakpoints.$screen-xs-max) {
        &.bt-sm-screen-xs {
            font-size: 16px;
            padding: 4px 15px;
        }

        &.bt-md-screen-xs {
            font-size: 18px;
            padding: 9px 15px;
        }

        &.bt-lg-screen-xs {
            font-size: 20px;
            padding: 12px 15px;
        }
    }

    &.call-to-action {
        margin: 0 15px;
    }
}

a.text-link, button.text-link {
    color: #444;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;

    &:hover {
        color: #777;
    }
}
