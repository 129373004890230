.terms-of-service {
    &__title {
        font-size: 26px;
        margin-bottom: 30px;
        line-height: 1.2;
    }

    &__main-text {
        margin-bottom: 60px;
    }

    &__link {
        display: block;
        color: #E00C00;
        text-decoration: underline;
    }

    &__main-button {
        width: 100%;
    }

    &__radio-check {
        text-align: center;
        margin-bottom: 10px;

        input {
            margin-right: 5px;
        }
    }

    .custom-radio-check {
        text-align: center;
        margin-bottom: 10px;
    }
}
