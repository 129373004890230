:root {
	--mp-red: #e00c00;
	--mp-red-hover: #b20000;
	--mp-red-lighter: #ea0d00;
	--mp-barely-red: #ffebea;
	--mp-red-active: #c00e00;
	--mp-main-dark: #222;
	--mp-gold: #ffb100;
	--mp-gold-darker: #e09900;
	--green: #4fd463;
	--medium-green: #39b14b;
	--white: #fff;
	--silver: #ccc;
	--gray: #666;

	--boost-purple: #4400bb;
	--boost-purple-lighter: #5d00ff;

	--secondary-soft: #f1f1f1;
	--secondary-extra-light: #e5e5e5;
	--secondary-light: #bbb;
	--secondary-medium: #333;
	--secondary-dark: #000;

	--currentConversation: #f5f5f5;
	--hoverConversation: #fafafa;
}
