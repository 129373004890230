@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

.boosted-container {
    position: relative;
    width: 100%;
    overflow-x: hidden;

    &__header {
        width: 100%;
        color: #222222;
        margin-bottom: 15px;
        position: relative;

        .boosted-title {
            font-size: 18px;
            line-height: 28px;
            font-weight: 700;
            display: flex;
            align-items: center;
            gap: 6px;

            &__icon {
                height: 22px;
                width: 22px;
                @include mixins.border-radius(50%);
                @include mixins.boxShadow(0, 5px, 10px, 0, 0, 0, 0, 0.2);
            }
        }
    }

    .scroll-button-degrade {
        position: absolute;
        z-index: 1;
        bottom: 31px;
        width: 143px;
        height: 249px;
        pointer-events: none;

        &--right {
            right: 0;
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #000000);
            @include mixins.border-radius(0px 4px 4px 0px);
        }

        &--left {
            left: 0;
            background-image: linear-gradient(to right, #000000, rgba(0, 0, 0, 0));
            @include mixins.border-radius(4px 0px 0px 4px);
        }
    }

    .scroll-button {
        cursor: pointer;
        position: absolute;
        top: 96px;
        height: 58px;
        width: 58px;
        border-radius: 50%;
        background: #FFF;
        @include mixins.boxShadow(0, 8px, 10px, 0, 0, 0, 0, 0.2);
        z-index: 1;
        pointer-events: auto;

        &--right {
            right: 20px;
        }

        &--left {
            left: 20px;
        }

        i.material-icons {
            font-size: 30px;
            margin: 13px;
        }
    }

    &__list {
        height: 270px;
        padding: 0 0 25px;
        -ms-overflow-style: none;
        position: relative;
        list-style: none;
        margin-bottom: 10px;
        width: 100%;

        &::-webkit-scrollbar {
            width: 0;
        }
    }
}

.container.boosted-mobile {
    padding-right: 0;
}

.boosted-card {
    @include mixins.border-radius(4px);
    height: 249px;
    width: 180px;
    margin-right: 9px;
    position: relative;

    background-color: #FFF;
    overflow: hidden;

    @media (min-width: breakpoints.$screen-md-min) {
      width: 167px;
    }

    @media (min-width: breakpoints.$screen-lg-min) {
        width: 168px;
    }

    @include mixins.boxShadow(0, 1px, 2px, 0, 0, 0, 0, 0.05);

    &__piclink {
        &:hover {
            text-decoration: none;
        }
    }

    &__elite-profile {
        margin: 5px 0 0 5px;
        position: absolute;
        background-image: linear-gradient(to right, #333333, #000000);
        height: 26px;
        @include mixins.border-radius(13px);
        color: #FFB100;
        font-size: 12px;
        line-height: 26px;
        padding-right: 10px;
    }

    &__avatar {
        width: 180px;
        height: 180px;
        object-fit: cover;

        @include mixins.can-hover {
            height: 180px;
        }
    }

    &__favorite-button.mp-main-button {
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mixins.border-radius(50%);
        @include mixins.boxShadow(0, 0, 3px, 0, 0, 0, 0, .2);
        position: absolute;
        top: 158px;
        right: 10px;
        z-index: 1;

        i.material-icons {
            color: unset;

            &.favorited {
                animation: dropIn 0.2s;
                color: var(--mp-red);
            }
        }
    }

    &__attributes-list {
        padding: 0;
        margin-top: 5px;
        position: absolute;
        width: 40px;
        right: 0;

        li {
            display: block;
            width: 100%;
            height: 26px;
            float: none;
            margin-bottom: 3px;
            margin-top: 0;
            padding: 0;
            position: relative;

            &.changed-attribute {
                margin-bottom: 14px;

                img {
                    width: auto;
                    height: auto;
                    box-shadow: none;
                    border-radius: unset;
                }
            }

            img {
                position: absolute;
                right: 5px;
                width: 26px;
                height: 26px;
                @include mixins.boxShadow(0, 0, 5px, 0, 0, 0, 0, 0.15);
                @include mixins.border-radius(50%);
            }
        }
    }

    &__info {
        height: 69px;
        width: 100%;
        color: #222222;
        padding: 9px 10px;
        line-height: 15px;

        .pics-count {
            display: flex;
            align-items: center;
            margin-bottom: 2px;

            i {
                font-size: 16px;
                margin-right: 6px;
            }

            div {
                font-size: 14px;
            }
        }
    }

    &__name {
        margin: 0;
        font-weight: 700;
        font-size: 15px;
        @include mixins.truncate-singleline(calc(100%));
        padding-bottom: 2px;
    }

    &__location {
        margin: 1px 0 0;
        @include mixins.truncate-singleline(calc(100%));
        padding-bottom: 1px;
        font-size: 12px;
    }
}
