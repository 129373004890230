@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

.testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__single-package {
        @media (min-width: breakpoints.$screen-sm-min) {
            width: 100%;
        }
    }

    &__single-package {
        @media (min-width: breakpoints.$screen-sm-min) {
            ul {
                display: flex;
            }

            h4 {
                font-size: 30px;
                margin: 10px;
                width: 100%;
                text-align: left;
            }

            .testimonial {
                margin: 20px;
            }
        }
    }

    &__list {
        position: relative;
    }

    &__title {
        text-align: center;
        margin: 40px;
    }
}

.testimonial {
    position: relative;
    background-color: #FFF;
    @include mixins.border-radius(12px);
    padding: 30px 20px 20px;
    margin: 0 2px 10px;
    @include mixins.boxShadow(0, 2px, 10px, 0, 0, 0, 0, .06);

    @media (min-width: breakpoints.$screen-md-min) {
        margin-bottom: 30px;
        max-width: 320px;

        &--full-width {
            max-width: 100%;
        }
    }

    @media (max-width: breakpoints.$screen-md-min) {
        margin-bottom: 30px;
    }

    &__quotation {
        padding-top: 17px;
        width: 60px;
        border-radius: 30px 30px 0 0;
        background-color: white;
        position: absolute;
        top: -14px;
        left: 7px;
        display: flex;
        justify-content: center;
    }

    &__comment {
        font-size: 16px;
        margin: 10px 0;
        z-index: 1;
        position: relative;
    }

    &__user {
        font-weight: 700;
        font-size: 15px;
        margin: 0;
    }

    &__time-since {
        font-size: 14px;
    }
}
