@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

body.registration {
    padding: 0 !important;

    main, header {
        @media (max-width: breakpoints.$screen-xs-max) {
            display: none;
        }
    }
}

.title {
    font-size: 26px;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 1.2;
}

.half-page-content {
    .step-box {
        border-radius: 12px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #FFF;
        margin-top: 30px;
        padding: 20px 0;

        @media (min-width: breakpoints.$screen-md-min) {
            padding: 30px 15px;
        }

        h2 {
            text-align: center;
            font-size: 22px;
            margin: 0 0 25px;

            @media (min-width: breakpoints.$screen-md-min) {
                font-size: 26px;
            }
        }
    }
}

.dial-box {
    padding: 30px calc(30px + env(safe-area-inset-right)) calc(30px + env(safe-area-inset-bottom)) calc(30px + env(safe-area-inset-left));
    background-color: #FFF;

    @media (min-width: breakpoints.$screen-sm-min) {
        padding: 30px;
        max-width: 600px;
        @include mixins.border-radius(12px);
    }
}

.complete-registration {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    overflow-y: auto;
    position: fixed;
    background: #FFF;
    -webkit-overflow-scrolling: touch;

    @media (min-width: breakpoints.$screen-sm-min) {
        padding: 30px;
        background: rgba(0,0,0,.85);

        .dial-box {
            margin: auto;
        }
    }

    .header.header-center {
        justify-content: center;
    }

    .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0;

        > div:first-child {
            width: calc(100% - 60px);
        }

        .page {
            font-size: 22px;
        }

        .avatar-container {
            width: 40px;
            height: 40px;
            @include mixins.border-radius(50%);
            margin-top: 24px;
            position: relative;
            overflow: hidden;

            img {
                width: 40px;
                height: 40px;
                object-fit: cover;
            }

            input {
                position: absolute;
                left: -500%;
                top: 0;
                width: 600%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }

            &.empty {
                background: #DDD url('../img/camera.svg') center center no-repeat;
                background-size: 18px auto;

                img {
                    display: none;
                }
            }
        }
    }
}

.complete-registration-onboarding {
    .business-info {
        margin-top: 50px;
    }
}

.step {
    padding-top: 30px;

    &.terms {
        padding-bottom: 0;
    }

    .title {
        margin-bottom: 30px;
        font-size: 26px;
        font-weight: 700;
        line-height: 1.2;
    }
}

.field-loading {
    @include mixins.loader(20px);

    & {
      margin-right: 0;
      position: relative;
      top: -30px;
      margin-bottom: -20px;
    }
}

.mp-main-button.bt-md {
    font-size: 18px;
    padding: 9px 15px;
}

.buttons-container {
    padding-top: 30px;

    .mp-main-button {
        width: 100%;
    }

    .text-link {
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }

    .custom-radio-check {
        text-align: center;
        margin-bottom: 10px;
    }

    .mp-main-button.outline-red.mp-confirmation-btn {
        width: 315px;
        font-size: 16px;

        @media (max-width: breakpoints.$screen-xs-max) {
            width: 100%;
        }
    }

    .btn-logout {
        background: none;
        display: block;
        font-size: 16px;
        line-height: 1.5;
        text-decoration: underline;
        text-align: center;
        color: inherit;
        border: 0;
        padding: 0;
        margin: 30px auto 0;

        @media (max-width: breakpoints.$screen-xs-max) {
            margin-top: 200px;
        }
    }
}

.form-field {
    margin-bottom: 30px;

    > * {
        display: block;
    }

    label {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: -5px;

        .material-icons.help {
            display: initial;
            margin: -8px 0 0 6px;
            padding: 0;
            font-size: 24px;
            line-height: normal;
            position: absolute;
            cursor: pointer;
        }
    }

    input, select, textarea {
        border: none;
        border-bottom: 1px solid #CCC;
        width: 100%;
        height: 40px;
        padding: 0;
        background-color: transparent;
        @include mixins.border-radius(0);

        &:hover {
            border-bottom: 1px solid var(--mp-red);
        }

        &:focus {
            border-bottom: 1px solid #000;
            outline: none;
        }

        &[disabled] {
            opacity: 0.5;
        }
    }

    textarea {
        height: auto;
        overflow: hidden;
        resize: none;
        padding: 8px 0;

        &.min-height {
            min-height: 82px;
        }
    }

    select {
        @include mixins.appearance-off();
        background: url('../img/chevron-down.svg') right center no-repeat;
        background-size: 10px auto;
    }

    select.placeholder-selected {
        color: rgba(0,0,0,.5);
    }

    .born {
        display: flex;
        justify-content: space-between;
        select {
            width: 30%;
        }
    }

    small {
        color: var(--mp-red);
        display: none;

        &.email-suggestion {
            color: #000;
            font-style: italic;
        }

        &.attention {
            color: #5b5b58;
        }

        &.warning {
            display: block;
            color: inherit;
        }

        &.ok {
            color: #0B3;
        }

        b {
            color: #0077CA;
            cursor: pointer;
        }
    }

    &.feedback {
        small {
            display: block;
        }

        .payment-tooltip {
            position: absolute;
            top: -4px;
            left: 38px;
            line-height: 1;
        }
    }

    &.hidden-list {
        transition: .5s;

        margin-left: -30px;
        margin-right: -30px;
        padding: 20px 30px;
        border-bottom: 1px solid #DDD;
        margin-bottom: 0;
        cursor: pointer;

        background: url('../img/chevron-down.svg') calc(100% - 30px) 27px no-repeat;
        background-size: 10px auto;

        label {
            font-size: 20px;
            margin-bottom: 0;
            line-height: 1;
            cursor: pointer;
        }
        select {
            transition: .5s;
            max-height: 0;
            opacity: 0;
        }

        ul.open-options {
            margin-top: 0;
            transition: .5s;
            max-height: 0;
            overflow: hidden;
            margin-bottom: 0;

            > li {
                display: block;
                width: 100%;
                float: none;
                padding: 0;
                margin-bottom: 1px;

                button {
                    @include mixins.appearance-off();
                    background-color: #FFF;
                    display: block;
                    width: 100%;
                    text-align: left;
                    border: none;
                    padding: 19px 12px;

                    @media (hover:hover) {
                        transition: all .3s ease;

                        &:hover {
                            padding-left: 20px;
                        }
                    }
                }

                &:first-child {
                    button {
                        @include mixins.border-radius(12px 12px 0 0);
                    }
                }
                &:last-child {
                    button {
                        @include mixins.border-radius(0 0 12px 12px);
                    }
                }
            }
        }

        &.active {
            background-color: #EEE;
            background-image: url('../img/chevron-up.svg');
            cursor: default;

            select {
                overflow: hidden;
                max-height: 40px;
                opacity: 1;
            }

            ul.open-options {
                display: block;
                margin-top: 10px;
                max-height: 1700px;
            }
        }

        &.chosen {
            padding: 10px 30px;

            label span {
                font-size: 18px;
                font-weight: 400;
                color: #0B3;
            }
        }
    }

    &.life-style-explaination {
        .title {
            margin-bottom: 8px;
        }
        small {
            display: block;
            color: inherit;
        }
    }
}

.remove-line {
    border: none;
    background: url('../img/circle-close.svg') center center no-repeat;
    width: 30px;
    height: 30px;
    vertical-align: top;
}

.line-option-checked {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    > div {
        width: calc(100% - 45px);

        .field-loading {
            display: inline-block;
            top: 0;
            vertical-align: top;
        }
    }
}

.main-term-text {
    font-size: 16px;
    line-height: 1.5;
    padding-bottom: 30px;

    a {
        display: block;
        color: var(--mp-red);
        text-decoration: underline;

        &:hover, &:active, &:visited {
            color: var(--mp-red);
        }
    }
}

.sign-up {
    .header {
        background-color: #000;
        height: 140px;
        display: flex;
        align-items: center;

        img {
            height: 25px;
        }

        h1 {
            color: #FFF;
            font-size: 18px;
            font-weight: 700;
            margin: 15px 0 0;

            @media (min-width: breakpoints.$screen-sm-min) {
                font-size: 22px;
            }
        }
    }

    .content {
        padding: 30px 15px;
    }
}

.confirmation-header {
    display: flex;
    align-items: center;
    flex-flow: wrap column;

    img {
        height: 25px;
        margin-bottom: 18px;
    }
}

.step-confirmation {
    #buttons-conf {
        text-align: center;
        padding-top: 0;
    }

    p {
        text-align: start;
    }

    a {
        display: inline;
        text-decoration: underline;
        color: var(--mp-red);
    }
}

.choose-cell {
    padding-bottom: 10px;

    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            width: calc(100% - 45px);
        }
    }

    .title {
        font-size: 20px;
        font-weight: 700;

        @media (min-width: 370px) {
            font-size: 22px;
        }

        span {
            color: var(--mp-red);
        }
    }

    .options {
        margin-top: 5px;
        li {
            margin-bottom: 5px;
        }
        button {
            background-color: #FFF;
            height: 70px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 22px;
            width: 100%;
            border: none;
            @include mixins.border-radius(35px);
            @include mixins.boxShadow(0, 0, 12px, 0, 0, 0, 0, .05);

            > div:first-child {
                width: calc(100% - 45px);
                text-align: left;
            }

            .check {
                @include mixins.border-radius(50%);
                height: 30px;
                width: 30px;
                border: 3px solid #DDDDDD;
            }

            @media (hover:hover) {
                transition: all .3s ease;

                &:hover {
                    padding-left: 25px;

                    .check {
                        border: 3px solid #999;
                    }
                }
            }
        }
    }

    &.e-mail {
        padding-top: 30px;

        .form-field {
            margin-bottom: 10px;
        }
    }

    button.mp-main-button {
        width: 100%;
        @include mixins.boxShadow(0, 10px, 15px, 0, 0, 0, 0, .2);
    }

    .text-link {
        font-size: 13px;
        color: inherit;
        text-decoration: underline;
    }
}

.sugar-explanation a {
    color: var(--mp-red) !important;
    font-size: inherit !important;
}

.bottom-login {
    padding-top: 20px;
    div {
        font-size: 14px;
    }
    a {
        color: var(--mp-red);
        font-size: 15px;
        font-weight: 700;
        text-decoration: underline;
    }
}

.looking-for {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 370px) {
        justify-content: flex-start;

        > div:last-child {
            margin-left: 24px;
        }
    }

    label {
        font-size: 20px!important;
        font-weight: normal;

        @media (min-width: 370px) {
            font-size: 24px!important;
            font-weight: normal;
        }
    }
}

.mini-terms {
    margin-top: 20px;
    font-size: 13px;

    a {
        text-decoration: underline;
        color: inherit;
        font-weight: 700;
    }
}

.mobile-nagbar {
    background-color: #FFF;
    padding: 20px 12px;
    @include mixins.border-radius(10px);
    margin: 0 0 20px;
    color: inherit !important;
    text-decoration: none !important;
    display: block;
    text-align: center;

    @media (max-width: breakpoints.$screen-sm-min) {
        position: relative;
        z-index: 1;
    }

    .head {
        &.incomplete {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .text {
                width: calc(100% - 100px);
                text-align: left;
            }
        }
    }

    .link-area {
        text-decoration: none;
        color: inherit;
    }

    .link-nagbar {
        color: var(--mp-main-dark);
        cursor: pointer;
    }

    .progress-circle {
        width: 60px;
        height: 60px;
        margin-right: 8px;
        position: relative;
    }

    .chevron {
        width: 20px;
        margin-left: 10px;
        img {
            width: auto;
            height: 18px;
        }
    }

    .percentage {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        line-height: 60px;
        text-align: center;
    }

    button {
        background-color: transparent;
        border: none;
    }

    .confirm-email {
        h1 {
            font-size: 18px;
            font-weight: 700;
            margin: 10px 0;
        }
    }

    .btn-control {
        text-align: center;

        .send-confirmation {
            color: var(--mp-red);
            font-weight: 600;
            border: 1px solid var(--mp-red);
            border-radius: 5px;
            padding: 7px 12px;
            margin-top: 15px;
        }

        .text {
            margin-top: 15px;
        }
    }

    .text.alert-visibility{
        margin-top: 15px;
    }

    .loader {
        margin-top: 15px;
    }

    .text.warning {
        padding-top: 15px;
        margin-top: 15px;
        border-top: 1px solid #DDD;
        width: 100%;
        font-size: 13px;
    }
}

.email-confirmation {
    padding: 20px;
    margin-bottom: 25px;
}

.fullscreen-loading {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: rgba(0,0,0,.85);
    z-index: 1000;
    color: #FFF;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    padding: 20px 15px;
    text-align: center;

    a {
        color: var(--white);
        text-decoration: underline;
    }

    .try-again {
        margin: 30px 0 20px;
    }
}

.fullscreen-loading > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fullscreen-loading button {
    margin-top: 12px;
}

.circular-chart {
    display: block;
    max-width: 100%;
    max-height: 250px;
}

.circle {
    stroke: var(--mp-red);
    fill: none;
    stroke-width: 4;
    stroke-linecap: round;
    animation: progress .5s ease-out forwards;
}

.circle-bg {
    stroke: #EEE;
    fill: none;
    stroke-width: 4;
    stroke-linecap: round;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

.pictures {
    display: flex;
    margin: 0 -5px;

    label.avatar-label {
        display: block;
        flex: 1;
        margin: 0 5px;
    }

    .avatar {
        @include mixins.aspect-ratio(1px, 1px);
        background-color: #DDDDDD;
        @include mixins.border-radius(6px);
        margin-bottom: 30px;
        font-size: 12px;
        text-align: center;
        font-weight: 400;
        position: relative;
        overflow: hidden;

        @media (min-width: breakpoints.$screen-sm-min) {
            max-width: 320px;
            margin: 0 auto 30px;
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: center;

            input {
                position: absolute;
                left: -500%;
                top: 0;
                width: 600%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }

        img.icon {
            width: 25px;
            height: auto;
            margin-bottom: 8px;
        }

        img.picture {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
