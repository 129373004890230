@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;
@use 'placeholders' as *;

@use 'config.scss';
@use 'skeleton.scss';
@use 'page-menu.scss';
@use 'mini-users-list.scss';
@use 'user-list.scss';
@use 'boosted-list.scss';
@use 'message-mine.scss';
@use 'messages-list.scss';
@use 'chat.scss';
@use 'profile.scss';
@use 'signup.scss';
@use 'payment.scss';
@use 'testimonials.scss';
@use 'login.scss';
@use 'search.scss';
@use 'photo-verify.scss';
@use 'payment-modal-header.scss';
@use 'payment-modal-form.scss';
@use 'express.scss';
@use 'outside-payment.scss';
@use 'checkbox.scss';
@use 'settings.scss';
@use 'notification.scss';
@use 'new-password.scss';
@use 'modals.scss';
@use 'referral.scss';
@use 'complete-registration.scss';
@use 'error-404.scss';
@use 'main-button.scss';
@use 'radio-check.scss';
@use 'new-upgrade-page.scss';
@use 'prizes.scss';
@use 'toast.scss';
@use 'feedback.scss';
@use 'rules-modal.scss';
@use 'trial-modal.scss';
@use 'explanation-modal.scss';
@use 'half-page.scss';
@use 'sbp.scss';
@use 'msg-limit-modal.scss';
@use 'edit-profile.scss';
@use 'flipdown-mp-theme.scss';
@use 'notifications-modal.scss';
@use 'frictionless.scss';
@use 'slidable-menu.scss';
@use 'user-avatar.scss';
@use 'close-menu-button.scss';
@use 'menu-plans-button.scss';
@use 'menu-user-buttons.scss';
@use 'menu-actions.scss';
@use 'submenu-v2.scss';
@use 'actionbar.scss';
@use 'email-unsubscribe.scss';
@use 'reactivate.scss';
@use 'picture-crop.scss';
@use 'activate-boost-button.scss';
@use 'boost-activatable.scss';
@use 'boost-activated.scss';
@use 'boost-bubbles.scss';
@use 'boost-progress.scss';
@use 'boost-generic-content.scss';
@use 'boost-activate-success.scss';
@use 'boost-profile-bubbles.scss';
@use 'boost-nagbar.scss';
@use 'boost-toast.scss';
@use 'boost-package-selection.scss';
@use 'boost-onboarding.scss';
@use 'boost-onboarding-cycle.scss';
@use 'boost-onboarding-promote.scss';
@use 'boost-onboarding-report.scss';
@use 'boost-onboarding-views.scss';
@use 'boost-container.scss';
@use 'boost-activated-card.scss';
@use 'boost-payment-modal.scss';
@use 'boost-running-nagbar.scss';
@use 'mp-safe-tip-nagbar.scss';
@use 'melt-modal.scss';
@use 'badges.scss';
@use 'modal-view.scss';
@use 'boost-multiplier.scss';
@use 'boost-multiplier-tiny.scss';
@use 'boost-balance.scss';
@use 'boost-results.scss';
@use 'payment-loading.scss';
@use 'melt-toast.scss';
@use 'visibility-modal.scss';
@use 'subscribed-status.scss';
@use 'membership-badge.scss';
@use 'membership-badge-extended.scss';
@use 'subscribe-button.scss';
@use 'my-subscription.scss';
@use 'registration-form-field.scss';
@use 'submitting-form.scss';
@use 'terms-of-service.scss';
@use 'registration-image-upload.scss';
@use 'confirm-email.scss';

@import '~photoswipe/dist/photoswipe.css';
@import '~photoswipe/dist/default-skin/default-skin.css';
@import '~nouislider/dist/nouislider.min.css';
@import '../vendor/bootstrap/css/bootstrap.min.css';
@import '~flipdown-mp/dist/flipdown.min.css';
@import '@angular/cdk/overlay-prebuilt.css';

/*==============================
=            Common            =
==============================*/

* {
	-webkit-font-smoothing: antialiased;
}

.cdk-overlay-backdrop {
    right: -1px;
}

.modal {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

body {
    font-size: 16px;
    background-color: #f1f1f1;
    color: var(--mp-main-dark) !important;
    padding-bottom: 20px;
    overflow-wrap: break-word;
    word-wrap: break-word;

    @media(min-width: breakpoints.$screen-md-min) {
        padding-top: 70px;
    }

    @media(max-width: breakpoints.$screen-md-min) {
        padding: 50px 0 calc(70px + env(safe-area-inset-bottom));
    }

    @media screen and (max-width: breakpoints.$screen-sm-max) {
        cursor: pointer;
    }

    &.no-padding {
        &--y-axis {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &.modal-opened {
        overflow: hidden;
    }
}

img {
    object-fit: cover;
}

select {
    overflow-wrap: normal;
    word-wrap: normal;
}

.text-btn {
    -webkit-appearance: none;
    padding: 0;
    background: transparent;
    border: none;
    color: var(--mp-red);

    &:hover {
        text-decoration: underline;
        color: var(--mp-red-hover);
    }
}

.private-photo-access {
    @media (max-width: breakpoints.$screen-sm-min) {
        margin-top: 8px;
    }
}

.text-break-line {
    white-space: pre-line;
}

.modal {
    overflow-y: auto;
}

.modal-alert {
    z-index: 1501;
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: 0;
}

h1 {
    @media (min-width: breakpoints.$screen-sm-min) {
        font-size: 36px;
    }
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1500;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

header {
    background-color: var(--mp-main-dark);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    @media (min-width: breakpoints.$screen-md-min) {
        height: 70px;
    }
}

mp-user-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: breakpoints.$screen-md-min) {
        height: 70px;
    }

    button {
        background-color: transparent;
        border: none;
        padding: 0;
        appearance: none;

        .avatar {
            @include mixins.square(36px);
            background-color: #CCC;
        }
    }

    .logo-container {
        display: flex;
        height: 100%;

        @media (max-width: breakpoints.$screen-md-min) {
            height: fit-content;
        }

        a {
            display: flex;
            align-items: center;

            img {
                height: 19px;
                margin-right: 0;
                width: auto;
                cursor: pointer;
                display: block;
                align-items: center;
            }
        }
    }
}

.flex-container {
    display: flex;
    align-items: center;
    nav {
        display: flex;
        justify-content: space-around;
        a {
            display: block;
            color: #FFFFFF;
            cursor: pointer;

            &:not(.mp-main-button) {
                height: 50px;
                @media (min-width: breakpoints.$screen-md-min) {
                    height: 70px;
                }
            }
        }
        a.regular-link {
            border-top: 4px solid #222;
            border-bottom: 4px solid #222;
            position: relative;
            width: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (min-width: breakpoints.$screen-md-min) {
                width: 82px;
                margin-left: 12px;
            }

            &:focus {
                color: #FFFFFF;
                text-decoration: none;
            }

            p {
                font-size: 13px;
                margin: -2px 0 0;

                @media (min-width: breakpoints.$screen-md-min) {
                    margin: 0 0 10px;
                }
            }

            .material-icons {
                font-size: 18px;
            }

            .notification-tip {
                background-color: #FF0000;
                position: absolute;
                display: flex;
                align-items: center;
                top: 0;
                left: 50%;
                border-radius: 10px;
                height: 20px;
                min-width: 20px;
                line-height: 20px;
                font-size: 11px;
                margin-left: 5px;
                color: #FFF;
                font-weight: 700;
                justify-content: center;

                @media (min-width: breakpoints.$screen-md-min) {
                    top: 4px;
                    margin-top: 0;
                }
            }

            &.active,
            &:hover {
                border-top: 4px solid var(--mp-red);
                color: #FFFFFF;
                text-decoration: none;
            }
        }
    }
}

mp-user-menu-items {
    position: fixed;
    bottom: 0;
    left: 0;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
    width: 100%;
    background: var(--mp-main-dark);
    z-index: 10;

    @media (min-width: breakpoints.$screen-md-min) {
        position: unset;
        padding-bottom: 0;
    }

    .user-menu-items {
        margin-left: 15px;
        margin-right: 15px;

        a.regular-link p {
            margin: 0;
        }
    }
}

/*==============================
=            Header            =
==============================*/
.header-v1 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    box-sizing: content-box;
    z-index: 100;
    background-color: var(--mp-main-dark);

    .logo-container {
        margin: 0 auto;
        width: 143px;
        height: 100%;
        display: flex;

        a {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
        }

        img {
            height: 14px;
            margin-right: 0;
            width: auto;
            cursor: pointer;
            display: block;
        }
    }

    .avatar {
        @include mixins.square(40px);
        @include mixins.border-radius(50%);
        background-color: #CCC;
    }

    @media(min-width: breakpoints.$screen-md-min) {
        height: 70px;

        .logo-container {
            margin: 0;
            width: 173px;
            float: left;
            align-items: center;
            height: 70px;

            a {
                justify-content: flex-start;
            }

            img {
                height: 17px;
                width: auto;
            }
        }
    }

    nav {
        display: flex;
        align-items: center;

        a {
            float: left;
            display: block;
            color: #FFF;
            cursor: pointer;

            &:not(.mp-main-button) {
                height: 70px;
            }
        }
        a.regular-link {
            text-align: center;
            padding: 10px 12px 0;
            border-top: 4px solid var(--mp-main-dark);
            border-bottom: 4px solid var(--mp-main-dark);
            margin: 0 10px;
            position: relative;

            .notification-tip {
                background-color: #FF0000;
                position: absolute;
                display: flex;
                align-items: center;
                top: 0;
                left: 50%;
                border-radius: 10px;
                height: 20px;
                min-width: 20px;
                line-height: 20px;
                font-size: 11px;
                margin-left: 5px;
                color: #FFF;
                font-weight: 700;
                justify-content: center;

                @media (min-width: breakpoints.$screen-md-min) {
                    top: 4px;
                    margin-top: 0;
                }
            }

            &:focus {
                color: #FFF;
                text-decoration: none;
            }

            p {
                font-size: 13px;
            }

            i {
                font-size: 18px;
            }

            &.active,
            &:hover {
                border-top: 4px solid var(--mp-red);
                color: #FFF;
                text-decoration: none;
            }
        }

        .dropdown, .dropdown-toggle {
            height: 70px;
        }

        .dropdown-menu {
            left: auto;
            right: 0;
            width: auto;

            li  {
                a {
                    color: #222;
                    height: auto;
                    float: none;
                    text-align: right;
                    padding: 8px 20px;
                }
            }
        }

        .dropdown-toggle {
            color: #FFF;
            background-color: transparent;
            border: none;
            padding-left: 20px;

            .caret {
                display: inline-block;
                margin-left: 5px;
            }

            i {
                font-size: 40px;
            }
        }
    }

    .search-field-container {
        width: 200px;
        height: 40px;
        background-color: rgba(255, 255, 255, .1);
        @include mixins.border-radius(20px);
        position: absolute;
        margin: 15px auto;
        left: 0;
        right: 0;

        input {
            height: 100%;
            border-radius: 22px;
            border: none;
            font-size: 14px;
            width: 100%;
            padding: 0 10px;
            background-color: transparent;
            color: #CCC;
            text-align: center;
        }
    }
}

/*==============================
=            Footer            =
==============================*/

footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
    background-color: var(--mp-main-dark);
    z-index: 100;

    nav {
        display: flex;

        a {
            position: relative;
            flex: 1;
            height: 50px;
            color: #DDD;
            padding: 6px 3px 0;
            text-align: center;

            .notification-tip {
                background-color: #FF0000;
                position: absolute;
                top: 4px;
                left: 50%;
                border-radius: 10px;
                height: 20px;
                min-width: 20px;
                line-height: 20px;
                font-size: 11px;
                padding: 0 3px;
                margin-left: 5px;
                display: block;
                color: #FFF;
                font-weight: 700;
            }

            p {
                font-size: .6875em;
                font-weight: 400;
                margin: -3px 0 0;
            }

            i {
                font-size: 20px !important;
            }

            &:hover {
                background-color: #3D3D3D;
                color: #DDD;
                text-decoration: none;
            }

            &.active {
                color: var(--mp-red);
                text-decoration: none;
                background-color: #3D3D3D;

                &:hover {
                    color: var(--mp-red);
                }
            }
        }
    }
}

.settings-content {
    @media(min-width: breakpoints.$screen-md-min) {
        padding-top: 40px;
    }

    @media(max-width: breakpoints.$screen-sm-max) {
        padding-top: 20px;

        > .row {
            > div:last-child {
                padding-top: 20px;
            }
        }
    }

    .text-blurb {
        display: inline;
    }
}

.privacy-text-container {
    text-align: justify;
    -webkit-hyphens: auto;
    hyphens: auto;

    ul {
        padding-left: 20px;
    }

    li {
        list-style: initial;
    }
}

.modal {
    display: block;
    background-color: rgba(0, 0, 0, 0.8);
}

.main-button {
    background-color: var(--mp-red);
    color: #FFF;

    &:hover, &:focus {
        opacity: 1;
        color: #FFF;
    }
}

.sidebar-container {
    position: fixed;
    height: min-content;
    padding-top: 70px;
    padding-right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.form-field .incomplete-field {
    background: var(--mp-barely-red);
}

.alert {
    white-space: pre-wrap;
    display: flex;
    justify-content: space-between;

    &-close {
        position: relative;
        top: -2px;
        right: -21px;
        font-size: 21px;
        font-weight: bold;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #FFF;
        filter: alpha(opacity=20);
        opacity: .2;
    }
}

.header {
    @media(max-width: breakpoints.$screen-md-min) {
        padding: 14px 14px 8px;
    }
}

@media (min-width: 1500px) {
    .container {
        width: 1450px;
    }
}

.cdk-virtual-scroll-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
}