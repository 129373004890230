@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

.melt-modal {
    margin: auto;
    position: relative;

    @media (max-width: breakpoints.$screen-xs-max) {
        width: 100vw;
        height: 100vh;
        max-width: unset;
    }

    &__box {
        width: 100%;
        height: 100%;
        margin: auto;
        position: relative;
        padding-bottom: 44px;
        background-image: linear-gradient(to bottom, #FEF0DC 72%, #FDE6C6);
        @include mixins.border-radius(4px);
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;

        .caramel-image {
            width: 100%;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        .melt-logo {
            display: flex;
            margin-top: -34px;
            width: 80%;
            align-items: flex-end;
            justify-content: space-between;

            @media (min-width: breakpoints.$screen-sm-min) {
                margin-top: -40px;
            }

            img {
                @media (min-width: breakpoints.$screen-sm-min) {
                    height: 128px;
                }
            }

            &::before {
                content: url('../img/ic_favorite.svg');
                display: block;
                width: 18px;
                margin-bottom: 17px;
            }

            &::after {
                content: url('../img/ic_favorite.svg');
                display: block;
                width: 18px;
                margin-bottom: 17px;
            }
        }

        button.modal-close {
            text-align: center;
            width: 32px;
            height: 32px;
            color: #FFF;
            padding: 0;
            border-radius: 100%;
            border: unset;
            position: absolute;
            left: 0;
            margin-top: 10px;
            margin-left: 15px;

            img {
                width: 30px;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .users-melt {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &__image {
                display: flex;
                margin-top: -2px;

                @media (min-width: breakpoints.$screen-sm-min) {
                    margin-top: -6px;
                }

                .user {
                    width: 139px;
                    height: 139px;
                    border: solid 5px #EE6361;
                    @include mixins.border-radius(20px);

                    img {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        z-index: 2;
                        @include mixins.border-radius(15px);
                    }

                    &--left {
                        transform: rotate(-9deg);
                        margin-right: 5px;

                        &::before {
                            content: url('../img/melting-red-2.svg');
                            position: absolute;
                            display: block;
                            bottom: -24px;
                            transform: rotate(10deg);
                        }

                        &::after {
                            content: url('../img/melting-red-1.svg');
                            position: absolute;
                            display: block;
                            bottom: -34px;
                            right: -5px;
                            transform: rotate(10deg);
                        }
                    }

                    &--right {
                        transform: rotate(9deg);

                        &::after {
                            content: url('../img/melting-red-2.svg');
                            position: absolute;
                            display: flex;
                            bottom: -20px;
                            right: -7px;
                            transform: rotate(-10deg);
                        }
                    }
                }
            }

            &__name {
                color: #DD802D;
                font-size: 24px;
                line-height: 1.33;
                font-weight: bold;
                margin-top: 20px;
                margin-bottom: 5px;

                span {
                    color: #EE6361;
                }
            }

            &__description {
                font-size: 18px;
                text-align: center;
                line-height: 1.33;
                font-weight: bold;
                color: #DD802D;
                margin-top: 0;
                width: 75%;
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
            height: 52px;
            font-size: 20px;
            font-weight: bold;
            padding: 0 50px;
            color: #FFF;
            background-color: #E00C00;
            border: unset;
            margin-top: 20px;
            @include mixins.border-radius(4px);
            @include mixins.boxShadow(0, 8px, 10px, 0, 0, 0, 0, 0.21);
        }

        span {
            text-decoration: underline;
            font-size: 16px;
            cursor: pointer;
            font-weight: 500;
            margin-top: 20px;
            line-height: 1.38;
        }
    }
}
