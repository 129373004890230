@use 'mixins' as mixins;
@use 'breakpoints' as breakpoints;

.profile-header {
    background-color: transparent;
}

.profile-avatar {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media(min-width: breakpoints.$screen-md-min) and (max-width: breakpoints.$screen-md-max) {
      height: 283px;
      width: 283px;
    }

    @media(min-width: breakpoints.$screen-md-max) and (max-width: breakpoints.$screen-lg-max) {
        height: 350px;
        width: 350px;
    }

    @media(min-width: breakpoints.$screen-xl-min) {
        height: 443px;
        width: 443px;
    }
}

.content {
    width: 100%;
    height: 100%;
}


.header-button {
    display: block;
    padding: 0 15px;
    height: 50px;
    color: #FFF;
    text-align: center;
    border: 0;
    background: transparent;

    &:hover {
        color: #FFF;
    }

    &.right-btn {
        text-align: right;
    }

    i,
    div {
        line-height: 50px;
        text-shadow: 0 0 15px #000;
    }

    &.logout {
        color: var(--mp-red);
    }
}

.profile-unavailable-container {
    .profile-unavailable {

        img {
            height: 60%;
            width: 100%;
            padding-top: 0;
            object-fit: cover;
        }
    }
}

.find-another-btn {
    display: inline-block;
    background: #E00C00;
    padding: 7px 30px;
    text-align: center;
    color: #FFF;
    font-weight: 700;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 10px;
    border: 0;

    &:hover {
        background-color: #C00E00;
        color: #FFFFFF;
    }
}

.swipe-right {
    transform: translateX(-100%);
}

.profile-carousel {
    position: relative;
    z-index: 0;
    padding-top: 100%;
    overflow: hidden;
    background: #E3E3E3;

    img {
        pointer-events: none;
    }

    .material-icons {
        font-size: 72px;
        color: #222222;
    }

    .mp-carousel-item {
        justify-content: center;
        align-items: center;
        display: inline-flex;
    }

    .mp-carousel {
        position: absolute !important;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        .mp-carousel-inner {
            display: flex;
            height: 100%;
            transition: transform .25s;
            cursor: grab;

            &.dragging {
                transition: none;
                cursor: grabbing;
            }

            .mp-carousel-item {
                width: 100%;
                height: 100%;
            }
        }
    }

    .mp-carousel-indicators {
        text-align: center;
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: 20px;
        pointer-events: none;
        left: 0;
        right: 0;
        list-style: none;
        display: flex;
        justify-content: center;

        li {
            background-color: #FFF;
            box-shadow: 0 0 5px black;
            margin: 0 4px;
            transition: background-color .3s;
            width: 8px;
            height: 8px;
            border-radius: 100%;

            &.active {
                background-color: var(--mp-red);
            }
        }
    }

    .leftRs,
    .rightRs {
        visibility: hidden;
    }
}

.owl-item {
    background: #000 url('../img/loading.gif') center center no-repeat;
    position: relative;
    width: 100%;
    height: 100%;
    background-size: 30px;

    &:empty {
        display: none;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.custom-object-fit {
    position: relative;
    background-size: contain;
    background-position: center center;
    .featured-image {
        opacity: 0;
    }
}

.basic-info {
    margin-bottom: 50px;
    position: relative;

    @media(max-width: breakpoints.$screen-sm-max) {
        margin-bottom: 20px;
        padding: 20px 0 0;
    }

    .row {
        display: flex;
        align-items: center;
    }

    h6 {
        margin: 0;
        font-size: 16px;
    }

    h4 {
        font-weight: 700;
        font-size: 26px;
        margin-bottom: 6px;
    }

    .btn-assign {
        display: block;
        height: 44px;
        background-color: var(--mp-red);
        border: none;
        color: #FFF !important;
        @include mixins.boxShadow(0, 8px, 10px, 0, 0, 0, 0, .2);

        @media(min-width: breakpoints.$screen-sm-min) {
            min-width: 166px;
            margin-right: 10px;
        }
    }

    .user-primary-info {
        margin-bottom: 20px;

        .online-tip {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-bottom: 3px;
        }

        .hidden-md .profile-badge {
            margin-top: 10px;
        }

        .profile-badge {
            @media (min-width: breakpoints.$screen-sm-max) {
                margin-bottom: 0;
            }
        }
    }

    .menu-col {
        width: 100%;
    }

    @media(min-width: breakpoints.$screen-md-min) {
        height: 170px;

        .menu-col {
            position:absolute;
            top: 5px;
            height: 170px;

            .options {
                position: absolute;
                bottom: 0;
            }

            a {
                cursor: pointer;
            }
        }
    }
}

mp-my-profile-details {
    .basic-info .user-primary-info .profile-badge {
        @media (min-width: breakpoints.$screen-md-min) {
            margin-left: 12px;
        }
    }
}

.more-information, .more-information-asked {
    margin-bottom: 40px;
}

h4.topic-title {
    margin: 0 0 8px;
    font-weight: 700;
}

.user-options-menu {
    display: flex;
    height: 40px;

    .dropdown-container {
        .dropdown-menu {
            left: auto;
            right: 15px;
            width: auto;
        }
    }

    .btn-fav, .message-btn {
        width: 220px;
    }

    .more-options {
        display: block;
        color: #FFF;
        height: 40px;
        padding: 0 12px;

        &:hover {
            text-decoration: none;
            background-color: #333;
            @include mixins.border-radius(4px);
        }

        > .content {
            display: flex;
            align-items: center;
            height: 100%;

            &:hover {
                color: unset;
            }
        }
    }

    .mp-main-button.bt-md {
        font-size: 14px;
        font-weight: 600;
        padding: 0;

        i {
            margin-right: 5px;
            font-size: 18px;
        }
    }

    > a {
        .content {
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: center;
        }

        font-size: 15px;
    }

    .btn-fav {
        margin: 0 5px 0;

        &.on {
            .off {
                display: none;
            }
        }

        &.off {
            .on {
                display: none;
            }
        }

        .on {
            i {
                color: var(--mp-red);
            }
        }
    }

    .btn-private-pic {
        &.locked {
            .unlocked {
                display: none;
            }
        }
        .flex-sm {
            @media (max-width: breakpoints.$screen-sm-max) {
                display: flex;
                justify-content: space-between;
            }
        }

        &.unlocked {
            .locked {
                display: none;
            }
        }

        .unlocked {
            i {
               color: turquoise;
            }
        }
    }
}

.details-list {
    li {
        border-bottom: 1px solid #DDD;

        &:last-child {
            border-bottom: 0;
            margin-bottom: 40px;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 14px 0;
        font-size: 18px;

        .skeleton-item {
            width: 90px;
            font-size: 12px;
            margin: 6px 0;
        }

        > div {
            width: 100%;
        }

        .detail-value {
            font-weight: 700;
            text-align: right;
            padding-left: 12px;
        }
    }
}

.thumbs-list-scroll {
    padding: 0 15px;
    overflow-x: auto;
    white-space: nowrap;
    width: calc(100% + 30px);
    margin-left: -15px;

    a {
        display: inline-block;
        cursor: pointer;
        margin-top: 6px;
    }

    .gallery-thumb {
        width: 110px;
        height: 110px;
        overflow: hidden;
        margin-right: 3px;
        background-color: #E3E3E3;

        img {
            float: left;
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }

    @media (min-width: breakpoints.$screen-md-min) {
        overflow-x: unset;
        white-space: unset;
        width: 100%;
        margin-left: 0;
        padding: 0;

        a {
            width: 16.6666%;
            padding: 0 6px;
        }

        .gallery-thumb {
            width: 110px;
            height: 110px;
            @include mixins.aspect-ratio(1px, 1px);
            margin-right: 0;
        }
    }

        @media (min-width: breakpoints.$screen-xl-min) {
        .gallery-thumb {
            width: 139px;
            height: 139px;
            @include mixins.aspect-ratio(1px, 1px);
            margin-right: 0;
        }
    }
}

.pic-badge {
    width: 30px;
    height: 30px;
    top: 4px;
    left: 4px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    i {
        font-size: 20px;
    }

    &.suspended {
        display: flex;
        background-color: var(--mp-red);
        color: #FFF;
    }

    &.approval  {
        display: flex;
        background-color: #FFDD00;
    }

    &.profile  {
        display: flex;
        background-color: #FFF;
        color: var(--mp-red);
    }
}

.badges-explanations {
    > div {
        margin: 10px 0;
        display: flex;
        align-items: center;

        .bold, .explanation-photo {
            margin-left: 10px;
        }

    }

    margin-bottom: 30px;
}

.thumbs-list {
    padding-right: 10px;
    padding-left: 10px;

    > div {
        padding: 0 5px;
    }

    .pic-badge {
        position: absolute;
    }

    img {
        width: 100%;
        height: 100%;
        float: left;
        background-color: #CCC;
        cursor: pointer;
    }

    a {
        cursor: pointer;
    }

    @media(min-width: breakpoints.$screen-md-min) {
        padding-right: 9px;
        padding-left: 9px;
    }
}

.cover-picture {
    height: 240px;
    width: 100%;
    background-color: var(--mp-main-dark);

    img {
        width: 100%;
        height: 100%;
        opacity: .3;
    }

    @media(max-width: breakpoints.$screen-sm-max) {
        background-color: transparent;
    }

    @media(min-width: breakpoints.$screen-md-min) {
        .profile-unavailable-info {
            position: absolute;
            left: 42vw;
            margin-top: 20px;
            color: #FFFFFF;

            p {
                width: 322px;
            }
        }

    }
}

.avatar-container {
    position: relative;
    height: auto;
    background-color: #E3E3E3;
    z-index: 1;

    .avatar {
        width: 100%;
        border: 5px solid white;
        margin: 0;
        @include mixins.boxShadow(0, 0, 5px, 3px, 0, 0, 0, .10);

        img {
            width: 100%;
            height: 100%;
            float: left;
        }
    }
}

.recent-activities {
    margin: 40px 0 0;
}

.profile-content {
    @media(min-width: breakpoints.$screen-md-min) {
        margin-top: -200px;
    }
}

.presentation-text-container {
    white-space: pre-line;
    position: relative;
    padding: 15px 0 0;
    @media (min-width: breakpoints.$screen-md-min) {
        padding: 15px 0 40px;
    }

    &.skeleton div {
        max-width: 337px;
        font-size: 8px;
    }

    &:not(.skeleton) {
        > div {
            &::before {
                content: '“';
            }
            &::after {
                content: '”';
            }
        }
    }
}

.presentation-text {
    @include mixins.border-radius(20px);
    background-color: #FFF;
    @include mixins.boxShadow(0, 0, 10px, 3px, 0, 0, 0, .05);
    padding: 10px 20px;
    font-size: 13px;
    max-width: 650px;
    text-align: center;
    margin: auto;
}

.arrow-up {
    @include mixins.getArrowUp(8px, #FFF);
    position: absolute;
    left: 0; right: 0;
    margin: -8px auto auto;
}

.arrow-left {
    @include mixins.getArrowLeft(8px, #FFF);
    position: absolute;
    top: 0; bottom: 0;
    margin-left: -6px;
}

.profile-big-col {
    @media(min-width: breakpoints.$screen-lg-min) {
        padding-left: 40px;
    }
}
.special-user {
    margin-bottom: -5px;
    align-items: baseline;

    img {
        height: 20px;
        width: auto;
        margin-right: 5px;
    }

    h6 {
        font-weight: 700;
    }

    &.feature-baby h6 {
        color: var(--mp-red);
    }

    &.elite h6 {
        color: var(--mp-gold);
    }

    &.premium {
        align-items: center;

        h6 {
            color: var(--mp-red);
        }
    }
}

.logged-user-options {
    display: flex;
    flex-direction: column;
    background: #FFF;
    align-items: center;
    justify-content: center;
    color: inherit;
    font-size: 12px;
    text-align: center;
    flex: 1;
    height: 44px;
    margin-right: 6px;
    line-height: 1;

    @media (min-width: breakpoints.$screen-sm-min) {
        width: 111px;
    }

    &:hover {
        color: inherit;
        text-decoration: none;
    }

    &:last-child {
        margin-right: 0;
    }

    @include mixins.border-radius(4px);
    @include mixins.boxShadow(0, 1px, 3px, 0, 0, 0, 0, .2);

    i {
        color: var(--mp-main-dark);
        font-size: 22px;
        position: relative;
        top: -2px;
    }
}

.box-not-active {
    color: #DDDD00;
    vertical-align: top;
}

.box-suspended {
    color: #DD0000;
    vertical-align: top;
}

.reason {
    font-size: 15px;
}

.delete_icon {
    position: absolute;
    left: 0;
    top: 0;
    color: #F1F1F1;
    cursor: pointer;
    text-shadow: 0 0 10px rgba(0, 0, 0, .5);
}

h6.center {
    text-align: center;
    font-weight: bold;
}

h3.center {
    text-align: center;
    font-weight: bold;
}

.report {
    text-align: center;
}

.gallery-thumb-btn {
    padding-top: 100%;
    position: relative;
    margin-bottom: 10px;

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    progress {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    .dropdown-menu {
        width: 130px;
        min-width: 0;
        color: #000;
        float: none;
        left: 50%;
        margin-left: calc(-130px * .5);

        li {
            &.divider {
                margin: 0;
            }

            a {
            padding: 8px;
            display: flex;
            align-items: center;

                i {
                    font-size: 20px;
                    margin-right: 4px;
                    margin-top: 3px;
                }
            }
        }
    }
}

.change-avatar-bg {
    position: absolute;
    bottom: 5px;
    left: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, .45);
    height: 50px;

    .confirm-btn {
        display: flex;
        align-items: center;
        background-color: var(--mp-red);
        height: 33px;
        @include mixins.border-radius(16px);
        color: #FFF;
        width: 90px;
        justify-content: center;
        margin: 6px auto auto;

        i {
            font-size: 20px;
        }

        p {
            margin: 0 3px 0 0;
            font-size: 13px;
        }

        &:focus,&:active,
        &:hover {
            text-decoration: none;
            background-color: var(--mp-red-hover);
            color: #FFF;
        }
    }

    .change-btn {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        color: #FFF;

        p {
            margin: 0 0 0 5px;
            font-size: 13px;
        }

        &:hover {
            color: var(--mp-red);
            text-decoration: none;
        }
    }

    .discard-btn {
        position: absolute;
        right: 10px;
        top: 11px;
        color: #FFF;
    }
}

.change-responsive-btn {
    display: flex;
    width: 100%;
    padding-top: 20px;
    margin-bottom: 12px;
    align-items: center;
    justify-content: flex-end;
    color: var(--mp-red);

    p {
        margin: 0 0 0 5px;
        font-size: 13px;
    }

    i {
        font-size: 15px;
    }

    &:active, &:focus, &:hover {
        color: var(--mp-red);
        text-decoration: none;
    }
}

.choose-avatar-overlay {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    background: black;
    z-index: 101;
    top: 0;
    overflow-y: auto;

    img {
        width: 100%;
        height: 100%;
    }

    .col-xs-3 {
        padding: 0 6px;
        margin-bottom: 12px;
    }

    .footer {
        position: fixed;
        width: 100%;
        padding: 10px 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-color: black;
        .select-btn {
            width: 100%;
            display: block;
            background-color: var(--mp-red);
            border: none;
            color: #FFF !important;
            margin: auto;

            @media(min-width: breakpoints.$screen-md-min) {
                width: 200px;
                margin: 0;
            }
        }
    }

    .header {
        color: #FFF;
        padding: 25px 0;

        div {
            padding: 0;
        }

        h2 {
            text-align: center;
            font-size: 16px;
            margin: 3px 0 0;
        }

        @media(min-width: breakpoints.$screen-sm-min) {
            padding: 40px 0;
            h2 {
                font-size: 20px;
            }
        }

        a {
            color: #FFF;

            &:hover, &:active, &:focus {
                color: #FFF;
            }
        }
    }

    @media(max-width: breakpoints.$screen-xs-max) {
        .row {
            margin: 0;
        }

        .col-xs-3 {
            padding: 0 2px;
            margin-bottom: 4px;
        }
    }
}

.avatar-select {
    cursor: pointer;
    background-color: #BBBBBB;

    opacity: .3;
    &.selected {
        opacity: 1;
    }
}

#close-avatar-overlay {
    cursor: pointer;
}

.popover-container {
    position: relative;
    display: inline-block;
    margin: -6px 0;
}

.help-tooltip-btn {
    vertical-align: middle;
    color: inherit;
    display: inline-block;
    margin: 0 0.25em;
    padding: 0;
    background: transparent;
    border: 0;

    & + .popover {
        position: absolute;
        bottom: 100%;
        top: auto;
        width: 276px;

        &:hover,
        &:focus {
            display: block;
        }
    }

    &:hover,
    &:focus {

        & + .popover {
            display: block;
        }
    }
}

.lifestyle-budget-label {
    display: inline;
}

#birthdate {
    &.invalid {
        background-color: var(--mp-red);
        color: #FFFFFF;
    }
}

@media (min-width: breakpoints.$screen-md-min) {

    .detail-title {
        white-space: nowrap;
    }
}

.personal-options-container {
    display: flex;
    align-items: center;
    margin: 15px 0 0;

    @media (max-width: breakpoints.$screen-sm-max) {
        display: block;

        > :first-child {
            margin-bottom: 20px;
        }
    }
}

.photo-request {
    text-align: center;
    color: #222222;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__pending {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 4px;
    }

    i {
        margin-bottom: 8px;
    }
}

.btn-request {
    width: 80%;
    display: block;
    background-color: var(--mp-red);
    border: none;
    color: #FFF !important;
    margin: 0 auto;
    padding: 10px;

    @media(min-width: breakpoints.$screen-md-min) {
        width: 200px;
        margin: 0;
    }
}

.topic {
    white-space: pre-line;
    margin-top: 40px;

    p {
        font-size: 18px;
        line-height: 28px;
    }
}

.add-photo {
    position: relative;
    padding: 20px 0;

    .logged-user-options {
        width: 100%;
        position: relative;
        overflow: hidden;
        @include mixins.border-radius(6px);
        @include mixins.boxShadow(0, 8px, 10px, 0, 0, 0, 0, .2);

        i {
            margin-right: 8px;
        }
    }

    .opacity-on-input {
        opacity: 0.5 !important;
    }

    label {
        margin-bottom: 0;
        vertical-align: top;
        cursor: pointer;
        width: 100%;
    }

    input[type="file"] {
        position: absolute;
        left: -300%;
        top: 0;
        width: 400%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
    }

    input[disabled] {
        cursor: not-allowed;
    }
}

.badge-verify, .badge-new {
    color: #000;
}

.profile-badge {
    height: 26px;
    @include mixins.border-radius(13px);
    padding: 0 10px 0 4px;
    font-size: 12px;
    background-color: #FFF;
    display: inline-block;
    line-height: 26px;
    margin-right: 5px;
    margin-bottom: 15px;
    @include mixins.boxShadow(0, 0, 5px, 0, 0, 0, 0, .2);

    &:first-child {
        margin-left: 0;
    }

    @media (min-width: breakpoints.$screen-md-min) {
        &:first-child {
            margin-left: 0;
            margin-bottom: 0;
        }
    }

    &.official-profile-badge {
      img {
        margin-left: 3px;
      }

      p {
        font-weight: 600;
      }
    }

    img {
        width: 18px;
        height: auto;
        margin-right: 5px;
    }

    > * {
        display: inline-block;
        vertical-align: middle;
        margin-top: -1px;
    }
}

.section-title {
    font-size: 26px;
    font-weight: bolder;
    word-break: break-all;

    &.skeleton-item {
        font-size: 22px;
        margin-bottom: 15px;
        width: 74px;
    }

    &.skeleton-details-title {
        width: 200px;
    }
}

.user-location {
    font-size: 18px;
}

.username-title, .user-location {
    @media (min-width: breakpoints.$screen-md-min) {
        color: #FFF;
    }
}

.username-title.skeleton-item {
    width: 250px;
    margin-bottom: 11px;
}

.user-location.skeleton-item {
    width: 150px;
    margin-bottom: 7px;
}

.private-count {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: breakpoints.$screen-md-min) {
        justify-content: unset;
    }

    .skeleton-item {
        width: 200px;
    }

    >div:first-child {
        display: flex;
        align-items: center;

        font-size: 14px;

        .material-icons {
            font-size: 16px;
            margin-right: 4px;
        }

        @media (min-width: breakpoints.$screen-md-min) {
            margin-right: 10px;
        }
    }
}

.profile-mob-footer {
    width: 100%;
    height: 100px;
    position: fixed;
    left: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(245,245,245,0) 0%,rgba(245,245,245,0.59) 7%,rgba(245,245,245,1) 15%,rgba(245,245,245,1) 100%);

    a.mp-main-button.bt-md.bt-message {
        width: calc(100% - 70px);
        div {
            font-size: 15px;
            font-weight: 600;
        }
    }
    a.bt-fav {
        height: 50px;
        width: 50px;
        background-color: #FFF;
        @include mixins.border-radius(50%);
        display: flex;
        justify-content: center;
        align-items: center;
        @include mixins.boxShadow(0, 0, 3px, 0, 0, 0, 0, .2);
        color: unset;
        text-decoration: unset;
        font-size: 0;

        i.material-icons {
            color: unset;

            &.active {
                color: var(--mp-red);
            }
        }
    }

    .content {
        width: 100%;
        padding: 0 15px;
        display: flex;
        nav {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }
}

.verify-photo-button {
    position: fixed;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -50%);
    display: flex !important;
    justify-content: center;
    align-items: center;

    &--verified-icon {
        margin-right: 5px;

        img {
            width: 23px;
        }
    }
}

.about-info {
    padding-bottom: 40px;

    .section-title.skeleton-item {
        width: 145px;
        margin-bottom: 17px;
    }

    .skeleton-line {
        height: 12px;
        margin-bottom: 14px;

        &.line-2 {
            width: 45%;
        }

        &.line-3 {
            width: 95%;
        }

        &.line-4 {
            width: 80%;
        }
    }
}

.btn-control {
    margin: 15px 0;
}

.photo-menu-btn {
    position: absolute;
    border: none;
    background: -moz-linear-gradient(45deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 43%, rgba(0,0,0,0.8) 100%);
    background: -webkit-linear-gradient(45deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 43%, rgba(0,0,0,0.8) 100%);
    background: linear-gradient(45deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 43%, rgba(0,0,0,0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=1);
    height: 32px;
    left: calc(100% - 32px);
    top: 0;
    width: 32px;

    i {
        position: absolute;
        right: 0;
        top: 0;
        color: #FFF;
        font-size: 28px;
        padding: 5px 0 5px 4px;
        cursor: pointer;
    }
}

.inline {
    display: inline;
}
