@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

.half-page-simple-header {
    background: #222;
    padding: 16px 0;

    img {
        display: block;
        margin: 0 auto;
        height: 18px;
    }
}

.half-page-content {
    padding-top: 30px;
    padding-bottom: 30px;

    @media (min-width: breakpoints.$screen-md-min) {
        padding-top: 58px;
        padding-bottom: 58px;
    }

    h1 {
        margin-top: 0;
        font-size: 22px;

        @media (min-width: breakpoints.$screen-md-min) {
            font-size: 30px;
        }
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
    }

    .buttons-container {
        padding-top: 0;
    }

    .profile-badge {
        margin-left: 0;
    }

    .img-block {
        margin-bottom: 44px;

        img {
            height: 24px;
        }
    }
}

.warning-text {
    margin-top: 50px;
    font-size: 13px;
}

.half-page-banner {
    @media (max-width: breakpoints.$screen-sm-max) {
        @include mixins.aspect-ratio(375px, 120px);
    }

    @media (min-width: breakpoints.$screen-md-min) {
        width: 50%;
        height: calc(100% - 70px);
        position: fixed;
        right: 0;
        bottom: 0;

        &.full-height {
            height: 100%;
        }

        .content {
            background-color: #DDD;
            position: absolute;
            height: 100%;
            width: 100%;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
